<script setup lang="ts" generic="T extends SelectOptionKey | undefined">
  import { useVModel } from '@vueuse/core'
  import BaseAssignmentIcon from '@/modules/base/components/BaseAssignmentIcon.vue'
  import BaseSelect from '@/modules/base/components/BaseSelect.vue'
  import type {
    SelectOption,
    SelectOptionKey,
  } from '@/modules/base/components/baseSelect'
  import type { Component } from 'vue'

  const props = withDefaults(
    defineProps<{
      options: SelectOption<T>[]
      label: string
      icon: Component
      modelValue: T
      forceEmptyState?: boolean
      valueAsTooltip?: boolean
    }>(),
    {
      modelValue: undefined,
      value: undefined,
      valueTooltip: false,
    }
  )

  const emit = defineEmits<{
    (event: 'update:modelValue', value: NonNullable<T>): void
  }>()

  const model = useVModel(props, 'modelValue', emit)
</script>

<template>
  <BaseSelect
    v-model="model"
    class="max-w-full"
    :classes="{
      toggle: 'items-center gap-2 border-none bg-transparent p-0',
    }"
    :options
  >
    <template #toggleContent="{ text }">
      <BaseAssignmentIcon
        v-tooltip="
          valueAsTooltip && text
            ? {
                content: text,
                options: { placement: 'top' },
              }
            : undefined
        "
        :force-empty-state
        :label
        :value="text"
        :value-placeholder="$t('global.placeholder.pleaseSelect')"
      >
        <template #icon>
          <component :is="icon" class="h-5.5 w-5.5" />
        </template>
      </BaseAssignmentIcon>
    </template>
  </BaseSelect>
</template>
