<script setup lang="ts">
  import { computed } from 'vue'
  import { ActionData } from '@/api/useActions.types'
  import IconScope from '@/assets/icons/Scope.svg'
  import { ActionScopeSubscope } from '@/modules/assessment/composable/useActionGroups.types'
  import { useScopeSelectOptions } from '../composable/useScopeSelectOptions'
  import ActionHeaderSelect from './ActionHeaderSelect.vue'

  const props = defineProps<{
    action: Partial<Pick<ActionData, 'scope' | 'subscope'>>
  }>()

  defineEmits<{
    (event: 'assign', value: ActionScopeSubscope): void
  }>()

  const modelValue = computed(() => {
    const { scope, subscope } = props.action
    return scope !== undefined && subscope !== undefined
      ? { scope, subscope }
      : null
  })
  const options = useScopeSelectOptions()
</script>

<template>
  <ActionHeaderSelect
    data-testid="action-header-scope"
    :icon="IconScope"
    :label="$t('global.action.assign.scope.label')"
    :model-value
    :options
    value-as-tooltip
    @update:model-value="$event && $emit('assign', $event)"
  />
</template>
