import { pick } from 'lodash'
import { useI18n } from 'vue-i18n'
import {
  scopeSubscopeToI18nKey,
  scopeSubscopes,
  stringifyScopeSubscope,
} from '@/modules/assessment/utils/scopeSubscope'

export const useScopeSelectOptions = () => {
  const { t } = useI18n()

  return scopeSubscopes.map((scopeSubscope) => {
    const scopeName = t(scopeSubscopeToI18nKey(scopeSubscope))
    return {
      key: pick(scopeSubscope, 'scope', 'subscope'),
      title: `${stringifyScopeSubscope(scopeSubscope)} ${scopeName}`,
    }
  })
}
