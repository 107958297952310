<script setup lang="ts">
  import { ActionCategory, ActionData } from '@/api/useActions.types'
  import IconCategory from '@/assets/icons/Category.svg'
  import { useCategoryConfig } from '@/modules/shared/composable/useCategoryConfig'
  import ActionHeaderSelect from './ActionHeaderSelect.vue'

  defineProps<{
    action: Partial<Pick<ActionData, 'category'>>
  }>()

  defineEmits<{
    (event: 'assign', value: ActionCategory): void
  }>()

  const { categoryConfig } = useCategoryConfig()

  const options = Array.from(categoryConfig).map(([key, value]) => ({
    key,
    title: value.title,
  }))
</script>

<template>
  <ActionHeaderSelect
    data-testid="action-header-category"
    :icon="IconCategory"
    :label="$t('global.action.assign.category.label')"
    :model-value="action.category"
    :options
    @update:model-value="$emit('assign', $event)"
  />
</template>
