<script setup lang="ts">
  import { computed } from 'vue'
  import { RoadmapPosition } from '@/api/useAction.types'
  import IconActions from '@/assets/icons/Actions.svg'
  import { SelectOption } from '@/modules/base/components/baseSelect'
  import { useDates } from '@/modules/shared/composable/useDates'
  import ActionHeaderSelect from './ActionHeaderSelect.vue'

  const props = defineProps<{
    value: Partial<RoadmapPosition>
  }>()

  defineEmits<{
    (event: 'assign', value: RoadmapPosition): void
  }>()

  const modelValue = computed(() => {
    const { targetYear, targetQuarter } = props.value
    return targetYear !== undefined && targetQuarter !== undefined
      ? { targetYear, targetQuarter }
      : null
  })

  const { firstYear, netZeroYear } = useDates()

  function* iterateRoadmapPositions(fromYear: number, toYear: number) {
    for (let year = fromYear; year <= toYear; year++) {
      for (let quarter = 1; quarter <= 4; quarter++) {
        yield { targetYear: year, targetQuarter: quarter }
      }
    }
  }

  const roadmapPositionLabel = (r: RoadmapPosition): string =>
    `Q${r.targetQuarter} ${r.targetYear}`

  const options = computed<SelectOption<RoadmapPosition>[]>(() => {
    return [...iterateRoadmapPositions(firstYear.value, netZeroYear.value)].map(
      (r) => ({
        key: r,
        title: roadmapPositionLabel(r),
      })
    )
  })
</script>

<template>
  <ActionHeaderSelect
    :icon="IconActions"
    :label="$t('global.action.assign.roadmapPosition.label')"
    :model-value
    :options
    @update:model-value="$event && $emit('assign', $event)"
  />
</template>
